import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { supabase } from "..";
import { toast } from "react-toastify";
import {
  INACTIVE_USER,
  PricingPlanCarouselSetting,
  PricingPlanCarouselSetting2,
  RealReviewsSetting,
  RoundButtonDark,
  calculateMaxHeight,
  checkPathType,
  siteMapping,
  toastobj,
  emojiDataNew,
  getFCMToken,
  sendAuditLogs,
  callUserDetails,
  setDefaultJournalSettings,
} from "../commonutilities";
import {
  createUser,
  freetrialVerifyUser,
  getMultipleAppConf,
  getReviewList,
  getallsubscriptiondetail,
  guestUpdateAccount,
  registrationMail,
  seventhplans,
  updateMetaData,
  verifyUser,
} from "../services/paymentService";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";

import {
  getFuturePredictions,
  getPopularEmotions,
  getPopularSymbols,
  saveGuestDataToPrev,
} from "../services/sightdreamService";
import AppDownloadButtons from "../component/AppDownloadButton";
import { confShowCare, confShowGuide, showSeventhArc } from "../bootapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import PopularSymbols from "../component/PopularSymbols";
import { setToken } from "../Features/CommonSlice";
import { useSelector } from "react-redux";
import { getAcessToken } from "../services/paymentService";
import { useDispatch } from "react-redux";
import FreeTrialComp from "../component/FreeTrialComp";

function formatNumber(number) {
  let numberString = Number(number).toFixed(2);
  numberString = numberString.replace(/(\.[0-9])0$/, "$1");
  return parseFloat(numberString);
}

const Home = (args) => {
  const token = useSelector((state) => state.theme.token);
  const dispatch = useDispatch();

  const [open, setOpen] = useState("1");
  const toggleAccordion = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const history = useNavigate();
  const [plans, setplans] = useState({
    month: {},
    year: {},
    showplan: false,
  });

  //new Seamless integration modal
  const [seamlessModal, setSeamlessModal] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [reviewList, setReviewList] = useState(null);
  const [isActiveFreeTrial, setIsActiveFreeTrial] = useState(false);
  const [popularSymbols, setPopularSymbols] = useState(null);
  const [popularEmotions, setPopularEmotions] = useState(null);
  const [loader, setLoader] = useState({ symbols: true, emotions: true });
  const [imageLoaded, setImageLoaded] = useState({});
  const [futurePercent, setFuturePercent] = useState(null);
  const [user, setUser] = useState(null);
  const [freeTrialDaysLeft, setFreeTrialDaysLeft] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (args?.isActiveFreeTrialNew !== null) {
      setIsActiveFreeTrial(args?.isActiveFreeTrialNew);
    }
  }, [args?.isActiveFreeTrialNew]);
  useEffect(() => {
    // if (args?.freeTrialDaysLeftNew !== null) {
    setFreeTrialDaysLeft(args?.freeTrialDaysLeftNew);
    // }
  }, [args?.freeTrialDaysLeftNew]);
  useEffect(() => {
    setIsActiveFreeTrial(false);
    setFreeTrialDaysLeft(null);
  }, [args?.clearState]);

  const handleImageLoaded = (url) => {
    setImageLoaded((prevState) => ({
      ...prevState,
      [url]: true,
    }));
  };

  async function handleRedirection() {
    const session = await supabase.auth.getSession();
    if (session?.data !== null) {
      const accessToken = session.data.session.access_token;
      const refreshToken = session.data.session.refresh_token;

      //redirection to app
      let deepLinkUrl = `seventhsight://login?access_token=${accessToken}&refresh_token=${refreshToken}`;
      const appType = searchParams.get("appType");

      if (appType) {
        switch (appType) {
          case "seventharc":
            deepLinkUrl = `seventharc://login?access_token=${accessToken}&refresh_token=${refreshToken}`;
            break;
          case "seventhguide":
            deepLinkUrl = `seventhguide://login?access_token=${accessToken}&refresh_token=${refreshToken}`;
            break;
          case "seventhjournal":
            deepLinkUrl = `seventhjournal://login?access_token=${accessToken}&refresh_token=${refreshToken}`;
            break;
          case "seventhcare":
            deepLinkUrl = `seventhcare://login?access_token=${accessToken}&refresh_token=${refreshToken}`;
            break;
          default:
            break;
        }
      }
      window.location.href = deepLinkUrl;
    }
  }
  useEffect(() => {
    const fromApp = searchParams.get("from") === "app";

    if (fromApp) {
      handleRedirection();
    }
  }, [location]);

  async function checkauth() {
    const session = await supabase.auth.getSession();
    let authuser = localStorage.getItem("sevensight_user");
    if (
      !authuser &&
      session.data &&
      session.data.session &&
      session.data.session.user
    ) {
      getAcessToken(session.data.session.access_token)
        .then((resp) => {
          if (resp?.data?.status) {
            dispatch(setToken(resp?.data?.auth_token));
            let new_newToken = resp?.data?.auth_token;
            getFCMToken(new_newToken);
            let user = session.data.session.user;
            let obj2 = { ...user };
            obj2["token"] = session.data.session.access_token;
            if (user["identities"][0]["identity_data"]) {
              if (user["identities"][0]["identity_data"]["full_name"]) {
                obj2["user_name"] =
                  user["identities"][0]["identity_data"]["full_name"];
              }
            }
            localStorage.setItem("sevensight_user", JSON.stringify(obj2));
            verifyUser(session.data.session.user["email"], new_newToken)
              .then((res) => {
                if (!res.data.user) {
                  toast.error(INACTIVE_USER, toastobj);
                  args.toggleLogout();
                  return;
                } else {
                  callUserDetails(
                    session.data.session.user.id,
                    session.data.session.user.email,
                    new_newToken,
                    setFreeTrialDaysLeft,
                    args,
                    true
                  );
                  /* getUserDetails(
                  session.data.session.user.id,
                  new_newToken
                ).then((response) => {
                  if (
                    response.data.status === true &&
                    response.data.status_code === 200
                  ) {
                    userDetails = response.data?.user;
                    sendAuditLogs(
                      session.data.session.user.id,
                      session.data.session.user.email.toLowerCase(),
                      "Login successful from web_site",
                      "Login"
                    );
                    if (!response.data?.user?.verified) {
                      freetrialVerifyUser(user.id, new_newToken);
                    }
                  }
                }); */
                }
              })
              .catch((error) => {
                try {
                  let obj = {
                    created_at: new Date(),
                    email: user["email"].toLowerCase(),
                  };
                  if (user["identities"] && user["identities"].length > 0) {
                    if (user["identities"][0]["identity_data"]) {
                      if (user["identities"][0]["identity_data"]["full_name"]) {
                        obj["name"] =
                          user["identities"][0]["identity_data"]["full_name"];
                      }
                    }
                  }
                  sendAuditLogs(
                    user?.id,
                    user["email"].toLowerCase(),
                    "Signup successful from web_site",
                    "Signup"
                  );

                  updateMetaData(user?.id, new_newToken).then(() => {});

                  let freeCreditValue = 0;
                  let freeTrialDays = 0;
                  getMultipleAppConf()
                    .then((res) => {
                      freeCreditValue = res?.data?.value?.free_credit_limit;
                      freeTrialDays = res?.data?.value?.free_trial;
                    })
                    .catch((err) => {})
                    .finally(() => {
                      registrationMail({
                        days: freeTrialDays,
                        email: user["email"],
                        source: "SeventhSIGHT",
                      });
                      const formdata = {
                        user_id: session.data.session.user["id"],
                        name: obj["name"],
                        email: session.data.session.user["email"],
                        created_at: session.data.session.user["created_at"],
                        updated_date: session.data.session.user["updated_at"],
                        guest_credits: freeCreditValue,
                        news_latter_unsubscribed: true,
                        signup_from: "web_site",
                      };

                      createUser(formdata, new_newToken)
                        .then(() => {
                          guestUpdateAccount(
                            session.data.session.user["email"]
                          ).then((res) => {});
                          freetrialVerifyUser(
                            session.data.session.user["id"],
                            new_newToken
                          ).then(() => {
                            setFreeTrialDaysLeft(freeTrialDays);
                          });
                        })
                        .catch((error) => {
                          // console.error("Error creating user:", error);
                        });

                      let tempSaveGuestData = {
                        email: session.data.session.user["email"],
                        user_id: session.data.session.user["id"],
                      };
                      saveGuestDataToPrev(
                        JSON.stringify(tempSaveGuestData)
                      ).then(() => {});
                    });
                } catch (error) {
                  sendAuditLogs(
                    user?.id,
                    user["email"].toLowerCase(),
                    `Create User Error:${error} in signup from web_site`,
                    "Signup"
                  );
                }
              });

            setDefaultJournalSettings(user.id, new_newToken);
          } else {
            sendAuditLogs(
              session.data.session.user?.id,
              session.data.session.user?.["email"].toLowerCase(),
              `Encode token Api Status False in login from web_site`,
              "Signup"
            );
          }
        })
        .catch((err) => {
          sendAuditLogs(
            session.data.session.user?.id,
            session.data.session.user?.["email"].toLowerCase(),
            `Encode token Api Err:${err} False in login from web_site`,
            "Signup"
          );
        });
    } else if (
      authuser &&
      session.data &&
      session.data.session &&
      session.data.session.user
    ) {
      let user = session.data.session.user;
      let obj2 = { ...user };
      obj2["token"] = session.data.session.access_token;
      if (user["identities"][0]["identity_data"]) {
        if (user["identities"][0]["identity_data"]["full_name"]) {
          obj2["user_name"] =
            user["identities"][0]["identity_data"]["full_name"];
        }
      }
      localStorage.setItem("sevensight_user", JSON.stringify(obj2));
      setDefaultJournalSettings(user.id, token);
    }
    if (session.data && session.data.session && session.data.session.user) {
      setUser(session.data.session.user);
      let redirectForOAuth = JSON.parse(
        sessionStorage.getItem("SeventhSightRedirect")
      );
      if (redirectForOAuth) {
        sessionStorage.removeItem("SeventhSightRedirect");
        let enredirectPath = redirectForOAuth?.redirectPath;
        const modifiedPath = enredirectPath.includes("/refresh_session/")
          ? enredirectPath.replace(
              "/refresh_session/",
              `/${session.data.session.refresh_token}/`
            )
          : enredirectPath;
        history(`/${modifiedPath}`);
      }
      getAcessToken(session.data.session.access_token).then((resp) => {
        if (resp?.data?.status) {
          dispatch(setToken(resp?.data?.auth_token));
          getallsubscriptiondetail(
            session?.data?.session?.user?.id,
            resp?.data?.auth_token
          )
            .then((res) => {
              if (res.data.status) {
                if (res.data.Subscriptions.length > 0) {
                  setIsActiveFreeTrial(true);
                  callUserDetails(
                    session.data.session.user.id,
                    session.data.session.user.email,
                    resp?.data?.auth_token,
                    setFreeTrialDaysLeft,
                    args,
                    false
                  );
                }
              }
            })
            .catch((err) => {
              callUserDetails(
                session.data.session.user.id,
                session.data.session.user.email,
                resp?.data?.auth_token,
                setFreeTrialDaysLeft,
                args,
                false
              );
            });
        }
      });
    }
  }
  useEffect(() => {
    async function fetchData() {
      setLoader({ symbols: true, emotions: true });

      try {
        const [symbolsRes, emotionsRes, futurePredictionRes] =
          await Promise.all([
            getPopularSymbols(),
            getPopularEmotions(),
            getFuturePredictions(),
          ]);

        if (symbolsRes?.data?.data) {
          setPopularSymbols(symbolsRes?.data?.data);
        }
        if (emotionsRes?.data?.data) {
          let emotionsData = emotionsRes?.data?.data;
          emotionsData.sort((a, b) => a.rank - b.rank);
          const rank1Index = emotionsData.findIndex(
            (element) => element.rank === 1
          );
          if (rank1Index !== -1) {
            const rank1Element = emotionsData.splice(rank1Index, 1)[0];
            const middleIndex = Math.floor(emotionsData.length / 2);
            emotionsData.splice(middleIndex, 0, rank1Element);
          }
          setPopularEmotions(emotionsData);
        }

        if (futurePredictionRes?.data?.future_prediction_score) {
          setFuturePercent(futurePredictionRes?.data?.future_prediction_score);
        }
      } catch (error) {
      } finally {
        setLoader((prev) => ({ symbols: false, emotions: false }));
      }
    }
    checkauth();
    getReviews();
    getplans();
    fetchData();
    return () => {};
  }, []);

  const getReviews = () => {
    const site = siteMapping[checkPathType()];
    getReviewList(site)
      .then((res) => {
        if (res?.status) {
          setReviewList(res?.data?.Reviews);
        }
      })
      .catch((err) => {});
  };
  const getplans = () => {
    seventhplans().then((response) => {
      if (response.data.status) {
        let year = {};
        let month = {};
        let arr = response.data.Plans.filter((ele) => {
          return ele["show_plan"];
        });
        arr.forEach((ele) => {
          if (ele.unique_key) {
            let spiltval = ele.unique_key.split("_");
            if (spiltval[0] === "month") {
              month[spiltval[1]] = ele;
            } else if (spiltval[0] === "year") {
              year[spiltval[1]] = ele;
            }
          }
        });
        if (Object.keys(month).length > 0) {
          setplans({ month, year, showplan: true });
        } else {
          setplans({ month, year, showplan: false });
        }
      }
    });
  };

  const [currentActiveTab, setCurrentActiveTab] = useState("2");
  const [currentActiveTimeTab, setCurrentActiveTimeTab] = useState("2");

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const toggleTrendingTab = (tab) => {
    if (currentActiveTimeTab !== tab) setCurrentActiveTimeTab(tab);
  };

  const Signuphandleplan = () => {
    if (localStorage.getItem("sevensight_user")) {
      history("/create_subscription");
    } else {
      args.setsignupmodalhome(true);
    }
  };

  const toggleSeamlessModal = () => {
    setSeamlessModal(!seamlessModal);
  };
  useEffect(() => {
    if (reviewList) {
      setMaxHeight(calculateMaxHeight(reviewList));
    }
  }, [reviewList]);

  return (
    <React.StrictMode>
      <div
        onClick={() => {
          args.toggleMenu();
        }}
      >
        <div className="new-home-bg">
          <img
            class="background-image-new-home-bg"
            alt="Background Image"
            onerror="this.style.display='none'"
          />
          <div className="hero-main">
            <Container>
              <Row>
                <Col md="6" className="mobile-text-center">
                  <h2>
                    ANALYSE YOUR <span>DREAMS</span> <br />
                    IN SECONDS
                  </h2>
                  <div className="mb-4">
                    <span>
                      SeventhSight app uses patented Machine Learning Artificial
                    </span>{" "}
                    <span>
                      Intelligence to analyse the meaning of your dream.
                    </span>{" "}
                    <span>Find powerful insights into your daily life by</span>
                    <span>
                      {" "}
                      understanding what your subconscious is telling you!
                    </span>
                  </div>
                  <Link to="/analyze">
                    <Button type="button" color="primary">
                      Analyze My Dream Now!
                    </Button>
                  </Link>
                  <AppDownloadButtons type="seventhsight" />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="hp-section1-new">
            <Container>
              <Row className="justify-content-center">
                <Col md="8">
                  <h2 className="text-center">
                    Why <span>understand</span> your dreams?
                  </h2>
                  <Row className="justify-content-center">
                    <Col md="7">
                      <p className="text-center main-text-color-new">
                        There are many benefits to understanding your
                        subconscious. It is a guide to many aspects of your life
                        always nudging you towards what's really important.
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4" className="commonlist">
                      <ul>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Better Relationships
                        </li>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Make Big Decisions
                        </li>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Find your Life Purpose
                        </li>
                      </ul>
                    </Col>

                    <Col md="4" className="commonlist">
                      <ul>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Repair Past Trauma
                        </li>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Improve your Career
                        </li>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Creative insights
                        </li>
                      </ul>
                    </Col>

                    <Col md="4" className="commonlist">
                      <ul>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Improve your intuition
                        </li>
                        <li>
                          <CheckCircleRoundedIcon className="hp-check-icon" />{" "}
                          Heal from Disease
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className="hp-section2-new">
          <img className="hp-section2-new-img-bg" loading="lazy" />

          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <div className="hp-section2-new-inn new-hp-section2-new-inn">
                  <h4 className="spaced-heading">
                    The privilege of a lifetime is to become who you really are
                  </h4>
                  <p>Carl Jung </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <div className="hp-section3-new">
            <img className="hp-section3-new-img-bg" loading="lazy" />
            <Container>
              <Row>
                <Col>
                  <h2 className="text-center mb-5">
                    How it <span>Works</span>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-5">
                  <div className="rounded bg-dark-blue p-3">
                    <div className="mb-4 mt-3">
                      <img
                        src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-seection2-icon4.png?t=2024-01-10T04%3A43%3A04.206Z"
                        alt="img"
                        loading="lazy"
                        className="icon-48"
                      />
                    </div>
                    <h5>Write your dream</h5>
                    <p className="main-text-color-new">
                      Enter as much of your dream that you can remember into
                      your personalised journal. Simply wright down where you
                      were or what you saw.
                    </p>
                  </div>
                </Col>
                <Col md="4" className="mb-5">
                  <div className="rounded bg-dark-blue p-3">
                    <div className="mb-4 mt-3">
                      <img
                        src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-seection2-icon5.png?t=2024-01-10T04%3A43%3A46.990Z"
                        alt="img"
                        loading="lazy"
                        className="icon-48"
                      />
                    </div>
                    <h5>Analyse your Dream</h5>
                    <p className="main-text-color-new">
                      We find meaning within the symbols of your dream. We
                      analyse patterns within those symbols and compare it with
                      your Arc personality.
                    </p>
                  </div>
                </Col>
                <Col md="4" className="mb-5">
                  <div className="rounded bg-dark-blue p-3">
                    <div className="mb-4 mt-3">
                      <img
                        src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-seection2-icon6.png?t=2024-01-10T04%3A44%3A09.384Z"
                        alt="img"
                        loading="lazy"
                        className="icon-48"
                      />
                    </div>
                    <h5>Practical Results</h5>
                    <p className="main-text-color-new">
                      Receive detailed meanings of your dream and our powerful
                      insights that we found within that meaning to use and
                      guide you in your daily life.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <Button
                    className="btn-primary"
                    onClick={() => {
                      if (localStorage.getItem("sevensight_user")) {
                        history("/analyze");
                      } else {
                        args.setsignupmodalhome(true);
                      }
                    }}
                  >
                    Try it now for free
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="hp-pricing-new-combine">
            <div
              className="hp-pricing-new "
              style={{ paddingTop: "0px! important" }}
            >
              <Container>
                <Row className="mb-3">
                  <Col md="6">
                    <h2>
                      our <span>Pricing</span> Plans
                    </h2>
                    <p>
                      Using powerful AI we created three platforms to understand
                      who you really are, to interpret your nightly visions, and
                      to improve your daily life based on who you are and what
                      your subconscious is telling you."
                    </p>
                    {!user?.email && (
                      <p style={{ fontWeight: "700" }}>
                        {args.noFreeTrialDays} Day Unlimited Use Free Trial on
                        All Plans!
                      </p>
                    )}
                  </Col>
                </Row>

                <Row className="paddingbottom50 home-tabs">
                  {plans["showplan"] && (
                    <Col>
                      <Nav tabs className="mb-5 pointer">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTab === "1",
                            })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Monthly
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTab === "2",
                            })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            Yearly
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={currentActiveTab}>
                        <TabPane tabId="1">
                          <div>
                            <Swiper loop {...PricingPlanCarouselSetting2}>
                              <SwiperSlide key={7}>
                                <Col>
                                  <div className="hp-pricing-box">
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className="hp-bundle-ribbon"
                                      data-ribbon="BUNDLE"
                                      // style={'--d:0px;--c:orange;--f:25px;'}
                                    ></div>
                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-integrated-package.png?t=2024-01-10T04%3A14%3A20.090Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg3-monthly${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>INTEGRATED PACKAGE</h5>
                                        {plans["month"]["all"]?.percentage_off >
                                          0 &&
                                        plans["month"]["all"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["month"]["all"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                ${plans["month"]["all"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["month"]["all"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                      </div>

                                      <div
                                        className="flexColStart w-100 rg-05"
                                        style={{
                                          marginBottom: "16px",
                                        }}
                                      >
                                        <div className="flexRowStart  w-100 al-center">
                                          <img
                                            style={{
                                              width: "16px",
                                              height: "16px",
                                            }}
                                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-tick-green.png"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                          <h6
                                            style={{
                                              margin: "0",
                                              textAlign: "start",
                                            }}
                                          >
                                            Al Powered Unlimited Dream Analysis
                                          </h6>
                                        </div>
                                        <div className="flexRowStart  w-100 al-center">
                                          <img
                                            style={{
                                              width: "16px",
                                              height: "16px",
                                            }}
                                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-tick-green.png"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                          <h6
                                            style={{
                                              margin: "0",
                                            }}
                                          >
                                            AI Powered Daily Journal
                                          </h6>
                                        </div>
                                        <div className="flexRowStart  w-100 al-center">
                                          <img
                                            style={{
                                              width: "16px",
                                              height: "16px",
                                            }}
                                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-tick-green.png"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                          <h6
                                            style={{
                                              margin: "0",
                                            }}
                                          >
                                            Coming Dec 2024: Included for Free
                                          </h6>
                                        </div>
                                      </div>

                                      <p
                                        style={{
                                          textAlign: "left",
                                        }}
                                      >
                                        Get even more insightful experience from
                                        the deeper discovery of your
                                        subconscious
                                      </p>
                                      <div
                                        className="flexRowStart w-100 al-center pointer"
                                        style={{
                                          justifyContent: "center",
                                        }}
                                        onClick={toggleSeamlessModal}
                                      >
                                        <p
                                          style={{
                                            color: "#A0A4D6",
                                            fontSize: "13px",
                                            lineHeight: "18px",
                                            fontWeight: "500",
                                            margin: "0",
                                          }}
                                        >
                                          Seamless Integration
                                        </p>
                                        <img
                                          style={{
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-help-circle.png"
                                          alt="icon"
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="pricing-bottom-zero">
                                        <Button
                                          onClick={Signuphandleplan}
                                          className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                        >
                                          Subscribe now
                                        </Button>
                                        {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SwiperSlide key={8}>
                                <Col>
                                  <div className="hp-pricing-box">
                                    {plans["month"]["sight"]?.percentage_off >
                                      0 &&
                                      plans["month"]["sight"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["month"]["sight"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img1.png?t=2024-01-10T04%3A18%3A45.558Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg3-monthly${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHSIGHT</h5>
                                        {plans["month"]["sight"]
                                          ?.percentage_off > 0 &&
                                        plans["month"]["sight"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["month"]["sight"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                $
                                                {plans["month"]["sight"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["month"]["sight"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        AI Powered Unlimited Dream Analysis
                                      </h6>
                                      <p>
                                        Find insights from your dreams to make
                                        actionable changes in your life.
                                        Understand the symbols and discover the
                                        patterns your subconscious is telling
                                        you.
                                      </p>
                                      <div className="pricing-bottom-zero">
                                        <Button
                                          onClick={Signuphandleplan}
                                          className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                        >
                                          Subscribe now
                                        </Button>
                                        {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SwiperSlide key={9}>
                                <Col>
                                  <div className="hp-pricing-box">
                                    {plans["month"]["journal"]?.percentage_off >
                                      0 &&
                                      plans["month"]["journal"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["month"]["journal"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img4.png?t=2024-01-10T04%3A25%3A22.143Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg3-monthly${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHJOURNAL</h5>
                                        {plans["month"]["journal"]
                                          ?.percentage_off > 0 &&
                                        plans["month"]["journal"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["month"]["journal"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                $
                                                {
                                                  plans["month"]["journal"]
                                                    ?.price
                                                }
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {plans["month"]["journal"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        AI Powered Daily Journal
                                      </h6>
                                      <p>
                                        Keep your thoughts noted, track your
                                        mood and get valuable insights with the
                                        help of an AI-powered Daily Journal
                                      </p>
                                      <div className="pricing-bottom-zero">
                                        <Button
                                          onClick={Signuphandleplan}
                                          className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                        >
                                          Subscribe now
                                        </Button>
                                        {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>

                              <SwiperSlide key={10}>
                                <Col>
                                  <div
                                    className={`hp-pricing-box ${
                                      confShowCare
                                        ? ""
                                        : "hp-pricing-box-disabled"
                                    }`}
                                  >
                                    {confShowCare &&
                                      plans["month"]["care"]?.percentage_off >
                                        0 &&
                                      plans["month"]["care"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["month"]["care"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/seventhcare_pricing_plan.png?t=2024-06-19T19%3A00%3A02.321Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg3-monthly${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHCARE</h5>
                                        {confShowCare &&
                                        plans["month"]["care"]?.percentage_off >
                                          0 &&
                                        plans["month"]["care"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["month"]["care"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                ${plans["month"]["care"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["month"]["care"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        AI Powered Therapist
                                      </h6>
                                      <p>
                                        Keep your thoughts noted, track your
                                        mood and get valuable insights with the
                                        help of an AI-powered Daily Journal
                                      </p>
                                      {confShowCare ? (
                                        <div className="pricing-bottom-zero">
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                          >
                                            Subscribe now
                                          </Button>
                                          {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                        </div>
                                      ) : (
                                        <h3>Coming Soon</h3>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SwiperSlide key={11}>
                                <Col>
                                  <div
                                    className={`hp-pricing-box ${
                                      confShowGuide
                                        ? ""
                                        : "hp-pricing-box-disabled"
                                    }`}
                                  >
                                    {confShowGuide &&
                                      plans["month"]["guide"]?.percentage_off >
                                        0 &&
                                      plans["month"]["guide"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["month"]["guide"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img3.png?t=2024-01-10T04%3A23%3A26.018Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg3-monthly${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHGUIDE</h5>
                                        {confShowGuide &&
                                        plans["month"]["guide"]
                                          ?.percentage_off > 0 &&
                                        plans["month"]["guide"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["month"]["guide"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                $
                                                {plans["month"]["guide"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["month"]["guide"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        Al Powered Guided Meditations
                                      </h6>
                                      <p>
                                        Use insights from SeventhSight and
                                        communicate directly with your
                                        subconscious to make radical positive
                                        health and habit changes at your core,
                                        and so much more!
                                      </p>
                                      {confShowGuide ? (
                                        <div className="pricing-bottom-zero">
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                          >
                                            Subscribe now
                                          </Button>
                                          {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                        </div>
                                      ) : (
                                        <h3>Coming Soon</h3>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>

                              <SwiperSlide key={12}>
                                <Col>
                                  <div className="hp-pricing-box hp-pricing-box-disabled">
                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img6.png?t=2024-01-10T04%3A29%3A54.183Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg3-monthly${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <h5>
                                        Seventh...?
                                        {/* SeventhGuide $6<sup>99</sup> */}
                                      </h5>

                                      <h3>
                                        Estimated <br />
                                        2024
                                      </h3>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SliderButtons />
                            </Swiper>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <Swiper loop {...PricingPlanCarouselSetting}>
                              <SwiperSlide key={1}>
                                <Col>
                                  <div className="hp-pricing-box">
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className="hp-bundle-ribbon"
                                      data-ribbon="BUNDLE"
                                      // style={'--d:0px;--c:orange;--f:25px;'}
                                    ></div>
                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-integrated-package.png?t=2024-01-10T04%3A14%3A20.090Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>INTEGRATED PACKAGE</h5>
                                        {plans["year"]["all"]?.percentage_off >
                                          0 &&
                                        plans["year"]["all"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["year"]["all"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                ${plans["year"]["all"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["year"]["all"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                        {plans["month"]["all"]?.percentage_off >
                                          0 &&
                                        plans["month"]["all"].show_plan ? (
                                          <div className="flexColStart al-center">
                                            <p className="text-center">
                                              ( $
                                              {formatNumber(
                                                plans["month"]["all"]
                                                  ?.special_price
                                              )}
                                              /mo )
                                            </p>
                                          </div>
                                        ) : (
                                          <div className="flexColStart al-center">
                                            <p className="text-center">
                                              ( ${plans["month"]["all"]?.price}
                                              /mo )
                                            </p>
                                          </div>
                                        )}
                                      </div>

                                      <div
                                        className="flexColStart w-100 rg-05"
                                        style={{
                                          marginBottom: "16px",
                                        }}
                                      >
                                        <div className="flexRowStart  w-100 al-center">
                                          <img
                                            style={{
                                              width: "16px",
                                              height: "16px",
                                            }}
                                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-tick-green.png"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                          <h6
                                            style={{
                                              margin: "0",
                                              textAlign: "start",
                                            }}
                                          >
                                            Al Powered Unlimited Dream Analysis
                                          </h6>
                                        </div>
                                        <div className="flexRowStart  w-100 al-center">
                                          <img
                                            style={{
                                              width: "16px",
                                              height: "16px",
                                            }}
                                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-tick-green.png"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                          <h6
                                            style={{
                                              margin: "0",
                                            }}
                                          >
                                            AI Powered Daily Journal
                                          </h6>
                                        </div>
                                        <div className="flexRowStart  w-100 al-center">
                                          <img
                                            style={{
                                              width: "16px",
                                              height: "16px",
                                            }}
                                            src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-tick-green.png"
                                            alt="icon"
                                            loading="lazy"
                                          />
                                          <h6
                                            style={{
                                              margin: "0",
                                            }}
                                          >
                                            Coming Dec 2024: Included for Free
                                          </h6>
                                        </div>
                                      </div>

                                      <p
                                        style={{
                                          textAlign: "left",
                                        }}
                                      >
                                        Get even more insightful experience from
                                        the deeper discovery of your
                                        subconscious
                                      </p>
                                      <div
                                        className="flexRowStart w-100 al-center pointer"
                                        style={{
                                          justifyContent: "center",
                                        }}
                                        onClick={toggleSeamlessModal}
                                      >
                                        <p
                                          style={{
                                            color: "#A0A4D6",
                                            fontSize: "13px",
                                            lineHeight: "18px",
                                            fontWeight: "500",
                                            margin: "0",
                                          }}
                                        >
                                          Seamless Integration
                                        </p>
                                        <img
                                          style={{
                                            width: "12px",
                                            height: "12px",
                                          }}
                                          src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/icon-help-circle.png"
                                          alt="icon"
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="pricing-bottom-zero">
                                        <Button
                                          onClick={Signuphandleplan}
                                          className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                        >
                                          Subscribe now
                                        </Button>
                                        {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SwiperSlide key={2}>
                                <Col>
                                  <div className="hp-pricing-box">
                                    {plans["year"]["sight"]?.percentage_off >
                                      0 &&
                                      plans["year"]["sight"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["year"]["sight"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img1.png?t=2024-01-10T04%3A18%3A45.558Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHSIGHT</h5>
                                        {plans["year"]["sight"]
                                          ?.percentage_off > 0 &&
                                        plans["year"]["sight"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["year"]["sight"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                ${plans["year"]["sight"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["year"]["sight"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                        {plans["month"]["sight"]
                                          ?.percentage_off > 0 &&
                                        plans["month"]["sight"].show_plan ? (
                                          <div className="flexColStart al-center">
                                            <p className="text-center">
                                              ( $
                                              {formatNumber(
                                                plans["month"]["sight"]
                                                  ?.special_price
                                              )}
                                              /mo )
                                            </p>
                                          </div>
                                        ) : (
                                          <div className="flexColStart al-center">
                                            <p className="text-center">
                                              ( $
                                              {plans["month"]["sight"]?.price}
                                              /mo )
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        AI Powered Unlimited Dream Analysis
                                      </h6>
                                      <p>
                                        Find insights from your dreams to make
                                        actionable changes in your life.
                                        Understand the symbols and discover the
                                        patterns your subconscious is telling
                                        you.
                                      </p>
                                      <div className="pricing-bottom-zero">
                                        <Button
                                          onClick={Signuphandleplan}
                                          className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                        >
                                          Subscribe now
                                        </Button>
                                        {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SwiperSlide key={3}>
                                <Col>
                                  <div className="hp-pricing-box">
                                    {plans["year"]["journal"]?.percentage_off >
                                      0 &&
                                      plans["year"]["journal"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["year"]["journal"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img4.png?t=2024-01-10T04%3A25%3A22.143Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHJOURNAL</h5>
                                        {plans["year"]["journal"]
                                          ?.percentage_off > 0 &&
                                        plans["year"]["journal"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["year"]["journal"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                $
                                                {
                                                  plans["year"]["journal"]
                                                    ?.price
                                                }
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["year"]["journal"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                        {plans["month"]["journal"]
                                          ?.percentage_off > 0 &&
                                        plans["month"]["journal"].show_plan ? (
                                          <div className="flexColStart al-center">
                                            <p className="text-center">
                                              ( $
                                              {formatNumber(
                                                plans["month"]["journal"]
                                                  ?.special_price
                                              )}
                                              /mo )
                                            </p>
                                          </div>
                                        ) : (
                                          <div className="flexColStart al-center">
                                            <p className="text-center">
                                              ( $
                                              {plans["month"]["journal"]?.price}
                                              /mo )
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        AI Powered Daily Journal
                                      </h6>
                                      <p>
                                        More than a journal, this AI-powered
                                        tool tracks your mood, analyzes
                                        patterns, and offers personalized
                                        insights and resource recommendations to
                                        help your personal growth and
                                        well-being.
                                      </p>

                                      <div className="pricing-bottom-zero">
                                        <Button
                                          onClick={Signuphandleplan}
                                          className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                        >
                                          Subscribe now
                                        </Button>
                                        {/*  {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>

                              <SwiperSlide key={4}>
                                <Col>
                                  <div
                                    className={`hp-pricing-box ${
                                      !showSeventhArc
                                        ? "hp-pricing-box-disabled"
                                        : ""
                                    }`}
                                  >
                                    {showSeventhArc &&
                                      plans["year"]["arc"]?.percentage_off >
                                        0 &&
                                      plans["year"]["arc"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["year"]["arc"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img2.png?t=2024-01-10T04%3A21%3A07.842Z"
                                      alt="img"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont ${
                                        showSeventhArc
                                          ? `hp-pricing-box-cont-bg3${
                                              isActiveFreeTrial
                                                ? "-nofreetrial"
                                                : ""
                                            }`
                                          : `hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg-new${
                                              isActiveFreeTrial
                                                ? "-nofreetrial"
                                                : ""
                                            }`
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHARC</h5>
                                        {showSeventhArc &&
                                        plans["year"]["arc"]?.percentage_off >
                                          0 &&
                                        plans["year"]["arc"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["year"]["arc"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                ${plans["year"]["arc"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["year"]["arc"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        Al Powered In-depth Personality Test
                                      </h6>
                                      <p>
                                        Find your archetype and your personal
                                        journey. We'll integrate your personal
                                        Archetype into your dedicated account
                                        for even better insights into your
                                        dreams and meditation visions.
                                      </p>

                                      <div
                                        className="flexRowStart w-100 al-center pointer"
                                        style={{
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "#A0A4D6",
                                            fontSize: "13px",
                                            lineHeight: "18px",
                                            fontWeight: "500",
                                            margin: "0",
                                          }}
                                        >
                                          *Single Charge, Free Integration
                                        </p>
                                      </div>
                                      {showSeventhArc ? (
                                        <div className="pricing-bottom-zero">
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                          >
                                            Subscribe now
                                          </Button>
                                        </div>
                                      ) : (
                                        <h3>Coming Soon</h3>
                                      )}

                                      {/* <Button
                                        onClick={Signuphandleplan}
                                        className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                      >
                                        {args.noFreeTrialDays} days free trial
                                      </Button> */}
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SwiperSlide key={5}>
                                <Col>
                                  <div
                                    className={`hp-pricing-box ${
                                      !confShowCare
                                        ? "hp-pricing-box-disabled"
                                        : ""
                                    }`}
                                  >
                                    {confShowCare &&
                                      plans["year"]["care"]?.percentage_off >
                                        0 &&
                                      plans["year"]["care"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["year"]["care"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/seventhcare_pricing_plan.png?t=2024-06-19T19%3A00%3A02.321Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont ${
                                        confShowCare
                                          ? `hp-pricing-box-cont-bg3${
                                              isActiveFreeTrial
                                                ? "-nofreetrial"
                                                : ""
                                            }`
                                          : `hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg-new${
                                              isActiveFreeTrial
                                                ? "-nofreetrial"
                                                : ""
                                            }`
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHCARE</h5>
                                        {confShowCare &&
                                        plans["year"]["care"]?.percentage_off >
                                          0 &&
                                        plans["year"]["care"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["year"]["care"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                ${plans["year"]["care"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["year"]["care"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                        {confShowCare && (
                                          <>
                                            {plans["month"]["care"]
                                              ?.percentage_off > 0 &&
                                            plans["month"]["care"].show_plan ? (
                                              <div className="flexColStart al-center">
                                                <p className="text-center">
                                                  ( $
                                                  {formatNumber(
                                                    plans["month"]["care"]
                                                      ?.special_price
                                                  )}
                                                  /mo )
                                                </p>
                                              </div>
                                            ) : (
                                              <div className="flexColStart al-center">
                                                <p className="text-center">
                                                  ( $
                                                  {
                                                    plans["month"]["care"]
                                                      ?.price
                                                  }
                                                  /mo )
                                                </p>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        AI Powered Therapist
                                      </h6>
                                      <p>
                                        Keep your thoughts noted, track your
                                        mood and get valuable insights with the
                                        help of an AI-powered Daily Journal
                                      </p>

                                      {confShowCare ? (
                                        <div className="pricing-bottom-zero">
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                          >
                                            Subscribe now
                                          </Button>
                                        </div>
                                      ) : (
                                        <h3>Coming Soon</h3>
                                      )}

                                      {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SwiperSlide key={6}>
                                <Col>
                                  <div
                                    className={`hp-pricing-box ${
                                      !confShowGuide
                                        ? "hp-pricing-box-disabled"
                                        : ""
                                    }`}
                                  >
                                    {plans["year"]["guide"]?.percentage_off >
                                      0 &&
                                      plans["year"]["guide"].show_plan && (
                                        <div
                                          className="hp-discount-ribbon"
                                          data-ribbon={`-${plans["year"]["guide"]?.percentage_off}%`}
                                          // style={'--d:0px;--c:orange;--f:25px;'}
                                        ></div>
                                      )}

                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img3.png?t=2024-01-10T04%3A23%3A26.018Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    {/* <div className="hp-pricing-box-free">
                                <img src={hpPricingFreeLabel} alt="" />
                              </div> */}
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <div className="flexColStart al-center">
                                        <h5>SEVENTHGUIDE</h5>
                                        {confShowCare &&
                                        plans["year"]["guide"]?.percentage_off >
                                          0 &&
                                        plans["year"]["guide"].show_plan ? (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              $
                                              {formatNumber(
                                                plans["year"]["guide"]
                                                  ?.special_price
                                              )}
                                            </h4>
                                            <h5 className="striked-discount-price">
                                              <s>
                                                ${plans["year"]["guide"]?.price}
                                              </s>
                                            </h5>
                                          </div>
                                        ) : (
                                          <div className="flexRowStart cg-05">
                                            <h4>
                                              ${plans["year"]["guide"]?.price}
                                            </h4>
                                          </div>
                                        )}
                                        {confShowCare && (
                                          <>
                                            {plans["month"]["guide"]
                                              ?.percentage_off > 0 &&
                                            plans["month"]["guide"]
                                              .show_plan ? (
                                              <div className="flexColStart al-center">
                                                <p className="text-center">
                                                  ( $
                                                  {formatNumber(
                                                    plans["month"]["guide"]
                                                      ?.special_price
                                                  )}
                                                  /mo )
                                                </p>
                                              </div>
                                            ) : (
                                              <div className="flexColStart al-center">
                                                <p className="text-center">
                                                  ( $
                                                  {
                                                    plans["month"]["guide"]
                                                      ?.price
                                                  }
                                                  /mo )
                                                </p>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <h6 className="text-center">
                                        AI Powered Guided Meditations
                                      </h6>
                                      <p>
                                        Use insights from SeventhSight and
                                        communicate directly with your
                                        subconscious to make radical positive
                                        health and habit changes at your core,
                                        and so much more!
                                      </p>

                                      {confShowCare ? (
                                        <div className="pricing-bottom-zero">
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-primary w-100 mt-3 hp-pricing-subscribe-btn"
                                          >
                                            Subscribe now
                                          </Button>
                                        </div>
                                      ) : (
                                        <h3>Coming Soon</h3>
                                      )}
                                      {/* {!isActiveFreeTrial && (
                                          <Button
                                            onClick={Signuphandleplan}
                                            className="btn-outlined w-100 mt-3 mb-3 hp-pricing-free-btn"
                                          >
                                            {args.noFreeTrialDays} days free
                                            trial
                                          </Button>
                                        )} */}
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>

                              <SwiperSlide key={7}>
                                <Col>
                                  <div className="hp-pricing-box hp-pricing-box-disabled">
                                    <img
                                      src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-pricing-img6.png?t=2024-01-10T04%3A29%3A54.183Z"
                                      alt="img"
                                      loading="lazy"
                                    />
                                    <div
                                      className={`hp-pricing-box-cont hp-pricing-box-cont-bg3 hp-pricing-box-cont-bg-new${
                                        isActiveFreeTrial ? "-nofreetrial" : ""
                                      }`}
                                    >
                                      <h5>
                                        Seventh...?
                                        {/* SeventhGuide $6<sup>99</sup> */}
                                      </h5>

                                      <h3>
                                        Estimated <br />
                                        2024
                                      </h3>
                                    </div>
                                  </div>
                                </Col>
                              </SwiperSlide>
                              <SliderButtons />
                            </Swiper>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
            <div style={{ paddingTop: "0px! important" }}>
              <Container>
                <Row className="mb-3">
                  <Col>
                    <h2 className="text-center mb-5">
                      Trending <span>Dream Symbols</span>
                    </h2>
                  </Col>
                </Row>
                {loader?.symbols ? (
                  <div
                    className="newloadercontainerForDiv "
                    style={{ position: "relative" }}
                  >
                    <span className="new_loaderForDiv "></span>
                  </div>
                ) : (
                  <Row className="dream-tabs">
                    <Col className="col md-6 md-12">
                      <Nav
                        tabs
                        className="mb-3 pointer"
                        style={{ justifyContent: "left" }}
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTimeTab === "1",
                            })}
                            onClick={() => {
                              toggleTrendingTab("1");
                            }}
                          >
                            24 hours
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTimeTab === "2",
                            })}
                            onClick={() => {
                              toggleTrendingTab("2");
                            }}
                          >
                            Last 7 days
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTimeTab === "3",
                            })}
                            onClick={() => {
                              toggleTrendingTab("3");
                            }}
                          >
                            Past 30 days
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: currentActiveTimeTab === "4",
                            })}
                            onClick={() => {
                              toggleTrendingTab("4");
                            }}
                          >
                            Last year
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <div className="col-md-6">
                        <p className="sub_para main-text-color-new">
                          Most impactful dream symbols that shape our nightly
                          narratives and reflect our innermost hopes and fears.
                        </p>
                      </div>
                      <TabContent activeTab={currentActiveTimeTab}>
                        <TabPane tabId="1">
                          <div>
                            <PopularSymbols
                              popularSymbols={popularSymbols?.["24hours"]}
                              imageLoaded={imageLoaded}
                              handleImageLoaded={handleImageLoaded}
                            />
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <PopularSymbols
                              popularSymbols={popularSymbols?.["7days"]}
                              imageLoaded={imageLoaded}
                              handleImageLoaded={handleImageLoaded}
                            />
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <div>
                            <PopularSymbols
                              popularSymbols={popularSymbols?.["30days"]}
                              imageLoaded={imageLoaded}
                              handleImageLoaded={handleImageLoaded}
                            />
                          </div>
                        </TabPane>
                        <TabPane tabId="4">
                          <div>
                            <PopularSymbols
                              popularSymbols={popularSymbols?.["365days"]}
                              imageLoaded={imageLoaded}
                              handleImageLoaded={handleImageLoaded}
                            />
                          </div>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                )}
              </Container>
            </div>
            <div className="emotional_analysis">
              <div className="emotional_analysis_section1">
                <img
                  class="emotional_analysis_section1-bg-img"
                  loading="lazy"
                />

                <Container>
                  <Row className="align-items-center">
                    <Col className="col-md-6">
                      <div className="side_content">
                        <h2>
                          EMOTIONAL <span>ANALYSIS</span>
                        </h2>

                        {loader?.emotions ? (
                          <div className="newloadercontainerForDiv ">
                            <span className="new_loaderForDiv "></span>
                          </div>
                        ) : (
                          <>
                            <p className="sub-heading-1">The last 24 hours</p>
                            <p className="sub_para main-text-color-new">
                              What is the global emotion for last 24 hours based
                              on everyones dreams.
                            </p>
                            <div className="emotion_tabs_outer">
                              <div className="emotion_tabs">
                                {/* Replace true/false with condition for active according to data */}
                                {popularEmotions &&
                                  popularEmotions.map((element, index) => {
                                    // Find the corresponding emoji object based on the text
                                    const emoji = emojiDataNew.find(
                                      (emojiObj) =>
                                        emojiObj.text === element.emotion
                                    );

                                    return (
                                      <div
                                        className={
                                          element?.rank === 1
                                            ? "card-emoji-selected"
                                            : "card-emoji"
                                        }
                                      >
                                        <img
                                          src={
                                            emoji && element?.rank !== 1
                                              ? emoji.image1
                                              : emoji.image2
                                          }
                                          alt="img"
                                          loading="lazy"
                                        />
                                        <p class="text-capitalize mt-2 fs-22">
                                          {element.emotion}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="emotional_analysis_background_mobile">
                        <img
                          class="emotional_analysis_section1-bg-img-mobile"
                          loading="lazy"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="emotional_analysis">
              <div className="dream_prediction_section1">
                <img class="dream_prediction_section1-bg-img" loading="lazy" />
                <Container>
                  <Row className="align-items-center">
                    <Col className="col-md-7 col-12"></Col>
                    <Col className="col-md-5 col-12">
                      <div className="side_content">
                        <h2>
                          DREAM <span>PREDICTIONS</span>
                        </h2>
                        <p className="sub-heading-1">The last 24 hours</p>
                        <p className="sub_para main-text-color-new">
                          Percentage of dreams that predicted the future within
                          the last 30 days.
                        </p>
                        {/* <h5>THESE RESULTS WILL BE COMING SOON!</h5> */}
                        <div className="flexRowStart w-100 al-center cg-4">
                          <div className="flexColStart al-center">
                            <div className="mt-2">
                              <div
                                className="pie animate"
                                style={{
                                  "--p": futurePercent || 0,
                                  "--c": "#6DE6EA",
                                }}
                              >
                                {futurePercent || 0}%
                              </div>
                            </div>
                            <p className="sub-heading-2">Dreams</p>
                          </div>
                          <h4 className="main-text-color-new">1 MONTH</h4>
                        </div>
                      </div>
                      <div className="dream_prediction_background_mobile">
                        <img
                          class="dream_prediction_background_mobile-bg-img"
                          loading="lazy"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
        <div className="hp-section2-new">
          <img className="hp-section2-new-img-bg" loading="lazy" />
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <div className="hp-section2-new-inn">
                  <h4 className="spaced-heading">
                    An uninterpreted dream is like an important phone call never
                    taken
                  </h4>
                  <p>The Talmud</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="">
          <div className="hp-section4-new">
            <Container>
              <Row className="align-items-center">
                <Col md="7">
                  <img
                    className="width-height-100"
                    src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp-seection4-img1.png"
                    alt="img"
                    loading="lazy"
                  />
                </Col>
                <Col md="5">
                  <h3>
                    The App is now <span>available</span>
                  </h3>
                  <p>
                    For the best experience use the App to journal your dreams
                    and get daily insights and Long Term Trends from the
                    Guidance of Your Subconscious!
                  </p>
                  <AppDownloadButtons type="seventhsight" />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <h2 className="text-center">
                    Options to <span>Analyze</span> your Dreams
                  </h2>
                </Col>
              </Row>

              <Row className="hp-section6-cont hp-options-analyse-desktop">
                <Col md="4">
                  <h4 className="text-center">Self Analysis</h4>
                  <span className="main-text-color-new">
                    Self-analyzing dreams offers personal interpretation and
                    intimacy. Dreamers use resources to interpret symbols,
                    providing autonomy to discern feelings and experiences.
                    However, it's time-consuming and requires learning a broad
                    range of symbols. Without guidance, dreamers may head down
                    incorrect paths. The benefit is a deep personal journey,
                    demanding patience and dedication.
                  </span>
                </Col>
                <Col md="4">
                  <h4 className="text-center">Dream Specialist</h4>
                  <span className="main-text-color-new">
                    Expert dream analysis offers a personalized, insightful
                    experience. Specialists understand symbolic language and the
                    uniqueness of each dreamer's subconscious expressions. Human
                    touch unveils profound interpretations reflecting personal
                    life and experiences, proving therapeutic and rewarding.
                    While expert guidance may be costly and time-consuming, the
                    clarity they provide can be valuable, especially for
                    profound dreams.
                  </span>
                </Col>
                <Col md="4">
                  <h4
                    className="text-center"
                    style={{ border: "1px solid #8850FF", color: "#8850FF" }}
                  >
                    Seventh Sight
                  </h4>
                  <span className="main-text-color-new">
                    SeventhSIGHT, an innovative dream interpretation tool,
                    employs advanced pattern recognition technology for instant
                    analysis. It combines global symbology with personal journal
                    entries, delivering insightful interpretations within
                    seconds. While lacking human intuition, it provides
                    round-the-clock access at a minimal cost, making it ideal
                    for those seeking instant insights and an objective
                    viewpoint. SeventhSIGHT's efficiency makes it a compelling
                    choice for modern dream exploration.
                  </span>
                </Col>
              </Row>

              <div className="hp-options-analyse-mobile">
                <Accordion open={open} toggle={toggleAccordion}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      Self Analysis
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      Self-analyzing dreams offers personal interpretation and
                      intimacy. Dreamers use resources to interpret symbols,
                      providing autonomy to discern feelings and experiences.
                      However, it's time-consuming and requires learning a broad
                      range of symbols. Without guidance, dreamers may head down
                      incorrect paths. The benefit is a deep personal journey,
                      demanding patience and dedication.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="2">
                      Dream Specialist
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      Expert dream analysis offers a personalized, insightful
                      experience. Specialists understand symbolic language and
                      the uniqueness of each dreamer's subconscious expressions.
                      Human touch unveils profound interpretations reflecting
                      personal life and experiences, proving therapeutic and
                      rewarding. While expert guidance may be costly and
                      time-consuming, the clarity they provide can be valuable,
                      especially for profound dreams.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">
                      Seventh Sight
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      SeventhSIGHT, an innovative dream interpretation tool,
                      employs advanced pattern recognition technology for
                      instant analysis. It combines global symbology with
                      personal journal entries, delivering insightful
                      interpretations within seconds. While lacking human
                      intuition, it provides round-the-clock access at a minimal
                      cost, making it ideal for those seeking instant insights
                      and an objective viewpoint. SeventhSIGHT's efficiency
                      makes it a compelling choice for modern dream exploration.
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </Container>
          </div>
        </div>

        <div className="hp_services_together">
          <img className="hp_services_together-bg-img" loading="lazy" />
          <Container>
            <Row>
              <Col>
                <h2 className="text-center">
                  See How all 5 services Work Together
                </h2>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <img
                  className="width-height-100"
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/interconnection-mid-img.png"
                  alt="img"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button onClick={() => history("/inter-connections")}>
                  Learn more
                </Button>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="hp-pricing-new-combine">
          {reviewList && (
            <div className="hp-pricing-new">
              <Container>
                <div className="hp-review new-hp-review">
                  <div
                    className="flexRowStart jc-spb w-100 mb-5"
                    style={window.innerWidth < 600 ? { flexWrap: "wrap" } : {}}
                  >
                    <h2>
                      Real reviews from <span>real</span> <br />
                      <span>people</span> like you!
                    </h2>

                    <button
                      onClick={args.toggleReview}
                      className="btn btn-primary d-block"
                    >
                      Add Review
                    </button>
                  </div>
                  <Swiper loop {...RealReviewsSetting}>
                    {reviewList.map((item) => (
                      <SwiperSlide key={item.id}>
                        <div
                          className="item hp-review-box"
                          style={{ height: maxHeight }}
                        >
                          <div className="flexRowStart w-100 jc-spb mb-2">
                            <div className="hp-review-title">{item.name}</div>
                            {/* <div className="hp-review-subtitle">{item.role}</div> */}
                            <div className="mb-2">
                              {Array.from({
                                length: parseInt(item.ratings),
                              }).map((_, index) => (
                                <FontAwesomeIcon
                                  key={index}
                                  icon={faStar}
                                  className="pointer icon-24 feature-icon"
                                  style={{ color: "gold" }}
                                />
                              ))}
                            </div>
                          </div>
                          <p>{item.body}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                    {reviewList.length > 1 && <SliderButtons />}
                  </Swiper>
                </div>
              </Container>
            </div>
          )}

          {/* </div> */}
          <Modal
            isOpen={seamlessModal}
            toggle={toggleSeamlessModal}
            style={
              window.innerWidth > 600
                ? {
                    // position: 'absolute',
                    top: "50%",
                    transform: "translateY(-50%)",
                  }
                : {
                    top: "0%",
                    transform: "translateY(0%)",
                  }
            }
            className="modal-lg"
          >
            <ModalHeader
              toggle={toggleSeamlessModal}
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              {window.innerWidth > 600 ? (
                <h2
                  style={{
                    fontSize: "20px",
                    lineHeight: "32px",
                    paddingTop: "30px",
                    fontWeight: "400",
                  }}
                >
                  While each of these services is independently designed,
                  together they weave a tapestry of insights about your inner
                  self
                </h2>
              ) : (
                <h5
                  style={{
                    fontSize: "20px",
                    lineHeight: "32px",
                    paddingTop: "30px",
                    fontWeight: "400",
                  }}
                >
                  While each of these services is independently designed,
                  together they weave a tapestry of insights about your inner
                  self
                </h5>
              )}
            </ModalHeader>
            <ModalBody style={{ paddingTop: "0px" }}>
              <div className="flexColCenter al-center ">
                <img
                  // className="icon-48"
                  style={
                    window.innerWidth > 600
                      ? {
                          flex: 1,
                          paddingTop: "16px",
                          paddingBottom: "16px",
                          maxWidth: "690px",
                          maxHeight: "196px",
                        }
                      : {
                          flex: 1,
                          paddingTop: "16px",
                          paddingBottom: "16px",
                          maxWidth: "358px",
                          maxHeight: "102px",
                        }
                  }
                  src="https://db.seventhsight.com/storage/v1/object/public/Website_Images/hp_services_icons.png?t=2024-01-10T04%3A54%3A02.823Z"
                  alt="img"
                  loading="lazy"
                />
              </div>
              <p style={{ textAlign: "center" }}>
                An example of their collaborative power might be, if you were
                anxious about an upcoming project, you then express this in
                SeventhJOURNAL as you spend a few moments reflecting on your
                day. The manifestation of a dream symbol that night, such as
                teeth falling out, analyzed by SeventhSIGHT, might then be
                linked to this underlying stress. As a response, SeventhGUIDE
                tailors your next day's meditation frequencies specifically to
                reduce your anxiety. The refined understanding of your archetype
                from SeventhARC enriches this process by identifying techniques
                best suited to your personality type. This detailed profile is
                then shared with your dedicated counselor in SeventhCARE,
                allowing them to provide personalized guidance, if you choose,
                alleviating any lingering anxiety, and providing pragmatic
                solutions to navigate life's challenges.
              </p>
            </ModalBody>
          </Modal>
        </div>
        {!isActiveFreeTrial && (
          <FreeTrialComp freeTrialDaysLeft={freeTrialDaysLeft} />
        )}
      </div>
      {/* Pricing old  */}
    </React.StrictMode>
  );
};

export default Home;
const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <div className="flexCenter w-100 cg-1" style={{ marginTop: "1rem" }}>
      <RoundButtonDark
        type={"backward"}
        filled={false}
        onClick={() => {
          swiper.slidePrev();
        }}
      />
      <RoundButtonDark
        type={"forward"}
        filled={false}
        onClick={() => {
          swiper.slideNext();
        }}
      />
    </div>
  );
};
